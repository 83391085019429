/*
  Paths
 */

$images-path: '/assets/img';
$icons-path: '/assets/img/icons';



/*
  Colors
 */

$domestiko-red: #c3063e;
$domestiko-pink: #D87C78;
$domestiko-blue: #0d3479;
$domestiko-blue-light: #6782b1;
$domestiko-gray: #EDEBEB;
$domestiko-light: lighten(#EDEBEB, 5%);
$domestiko-sand: #D4CEC2;
$domestiko-soil: darken($domestiko-sand, 50%);
$domestiko-dark: #221D07;


$dmtk-border-radius: 2px;

/*
  Base
 */

$base-body-color: #333;
$text-primary-color: $domestiko-red;
$global-font-family: "Open Sans", "Noto Sans", sans-serif;
$base-selection-background: $domestiko-red;

$base-link-color: $domestiko-blue;
$global-link-color: $domestiko-blue;
$base-link-text-decoration: text-underline;

$base-h1-font-size: 1.8rem;
$base-heading-color: $domestiko-red;
$base-heading-font-family: $global-font-family;

$width-xlarge-width: 640px;
$tooltip-max-width: 300px;

/*
  Navbar
 */

$navbar-nav-item-color: $domestiko-dark;
$navbar-dropdown-background: $domestiko-gray;
$navbar-dropdown-nav-item-color: $domestiko-dark;
$navbar-dropdown-nav-item-active-color: white;
$offcanvas-overlay-background: rgba(0,0,0,0.75);

/*
  Offcanvas
 */

$offcanvas-bar-width: 420px;
$offcanvas-bar-width-m: 420px;
$offcanvas-z-index: 2000;

/*
  Modal
 */

$modal-z-index: 3000;


/*
  Breadcrumbs
 */

$breadcrumb-divider: '>';
$breadcrumb-divider-color: $domestiko-dark;
$breadcrumb-divider-margin-horizontal: 5px;

$breadcrumb-item-color: $domestiko-red;
$breadcrumb-item-active-color: $domestiko-dark;

/*
  Badge
 */

$badge-background: $domestiko-red;

/*
  Buttons
 */


$button-primary-color: white;
$button-primary-background: $domestiko-red;
$button-secondary-background: $domestiko-dark;

/*
  Forms
 */

$form-color: $domestiko-dark;
$form-focus-color: $domestiko-dark;
$form-background: white;
$form-focus-background: white;

$form-focus-border: $domestiko-red;
$form-radio-background: transparent;
$form-radio-checked-background: $domestiko-red;
$form-radio-checked-focus-background: $domestiko-red;
$form-radio-focus-border: $domestiko-red;
$form-select-icon-color: $domestiko-blue;

/*
  Pagination
 */

$pagination-item-color: $domestiko-dark;

/*
  Inversed
 */

$inverse-global-color-mode: 'dark';
$inverse-global-color: white;
$inverse-global-muted-color: rgba(255,255,255,0.75);

/*
  Paths
 */

$assets: '/assets'