
[v-cloak]{
  display: none;
}

[data-href]{
  cursor: pointer;
}

html{
  scroll-padding-top: 90px;
  &:has(> body.panel){
    scroll-padding-top: 128px;
  }
}

.underlined{
  text-decoration: underline;
}
.not-underlined{
  text-decoration: none;
}

.clickable {
  cursor: pointer;
}

.rounded-30{
  border-radius: 30px;
}

html {
  &:not(.e2e){
    scroll-behavior: smooth;
  }
}
/*
*{
  content-visibility: auto
}
*/

body.loggedin{
  .-unlogged{
    display: none;
  }
}