
.share-buttons{

  display: flex;

  .uk-button{
    &.-share{

      padding: 0 15px;
      flex-grow: 1;

      color: white;
      background: $domestiko-sand;

      span{
        padding-left: 32px;
        background: url('#{$icons-path}/basic/whatsapp.svg') no-repeat left center;
      }

      &.-linkedin{
        background: #2867B2;
        span{ background-image: url('#{$icons-path}/basic/linkedin.svg'); }
      }
      &.-facebook{
        background: #3B5998;
        span{ background-image: url('#{$icons-path}/basic/facebook.svg'); }
      }
      &.-whatsapp{
        background-color: #00776B;
        span{ background-image: url('#{$icons-path}/basic/whatsapp.svg'); }
        /* background-color: #1ebea5; */
      }
      &.-email{
        background-color: $domestiko-red;
        span{ background-image: url('#{$icons-path}/basic/mail.svg'); }
      }

    }
  }


}
