
#main-menu{

  top: 0;
  z-index: 2000;
  position: fixed;
  width: 100vw !important;
  background: $domestiko-gray;

  > .uk-container{
    padding: 0 15px;
  }

  .uk-logo{
    width: 160px;
    max-width: 160px;
    padding: 0;
  }

  .uk-navbar-nav{
    > li{
      > a{

        font-weight: 600;
        color: $domestiko-blue;

        &.hover,
        &:hover{
          color: white;
          background: $domestiko-red;
        }

        &.uk-logo,
        &.uk-button,
        &.uk-icon-link{
          &:hover{
            color: inherit;
            background-color: transparent;
          }
        }

        &.uk-open,
        &.uk-button-primary{
          color: white;
          background: $domestiko-red;
          &:hover{
            color: white;
            background: $domestiko-dark;
            border-color: $domestiko-dark;
          }
        }
        &.uk-button-ghost{
          color: $domestiko-red;
        }

        @media screen and (min-width: $breakpoint-medium) {
          color: $domestiko-dark;
        }
      }
      &.uk-active > a{
        color: $domestiko-red;
        background: transparent !important;
        &.hover,
        &:hover{
          color: white;
          background: $domestiko-red !important;
        }
      }
    }
  }

  .uk-navbar-dropdown{

    $nav-width: 310px;
    width: $nav-width * 2;
    background: transparent;
    @extend .uk-padding-remove;

    ul.uk-list{ margin: 0; }

    li{
      margin: 0;
      a{
        padding: 8px 16px;
        display: block;
        color: $domestiko-dark;
        &:hover{
          text-decoration: none;
        }
      }
      &.uk-active{
        background: $domestiko-red;
        a{
          color: white;
        }
      }
      &.publish{
        border-bottom: solid 1px $domestiko-gray;
        a{
          color: $domestiko-red;
        }
      }
      &.all{
        border-top: solid 1px $domestiko-gray;
        a{
          color: $domestiko-red;
        }
      }
    }

    .sectors{
      width: $nav-width;
      background: $domestiko-light;
      border-right: solid 1px $domestiko-gray;
      max-height: calc(100vh - 80px);
      overflow: auto;
      a{
        font-weight: bold;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
    .activities{
      top: 0;
      width: $nav-width;
      left: $nav-width + 1px;
      position: absolute;
      padding: $navbar-dropdown-padding;
      background: $domestiko-light;

      a{
        text-decoration: none;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }

  .uk-button-text,
  a.uk-icon-link{
    padding: 10px;
    font-size: 2.25em;
    color: $domestiko-blue;
  }

  .uk-helper-nav{
    > li{
      display: flex;
      align-items: center;
      > a{
        min-height: auto;
      }
    }
  }

  .user-nav{
    .uk-dropdown-nav{
      a{
        color: $domestiko-dark;
        &:hover{
          text-decoration: underline;
        }
      }
    }
    a.search-trigger{
      &.-open{
        i.icon-search{
          background: $domestiko-sand;
          border-radius: 50%;
        }
      }
    }
  }

  /*
    En los index, cuando no está activo
   */
  @at-root {
    body{
        padding-top: 80px;
        &.index,
        &.signup{
          padding-top: 0;
        }
    }
  }
  body.index:not(.-search-bar) &:not(.uk-active) {
    color: white;
    background: transparent;

    .uk-logo {
      img { display: none; }
      background: url('/assets/img/logo/logo-horizontal-negativo.png') no-repeat center center / contain;
    }

    a.uk-icon-link,
    .uk-button-text,
    .uk-navbar-item > a,
    .uk-navbar-nav > li > a{
      color: white;
    }

    .uk-button-ghost{
      color: white;
    }

  }

}

#search-bar{
  z-index: 1999;
  opacity: 0;
  position: fixed;
  padding-bottom: 20px;
  background: $domestiko-sand;
  top: 80px; left: 0; right: 0;
  transform: translateY(-100%);
  transition: all 0.25s ease;
  pointer-events: none;
  body.-search-bar &{
    opacity: 1 !important;
    transform: translateY(0%);
    pointer-events: all;
  }
}