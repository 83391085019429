
.landing.jobs.-publish {

  > .hero {
    display: flex;
    align-items: center;
    position: relative;

    aspect-ratio: 9/16;
    background-image: url("#{$images-path}/layout/hero-blue-mobile.svg"), url("#{$images-path}/layout/hero-red.svg");
    background-position: left center, right center;
    background-repeat: no-repeat;
    background-size: 56vw, 16vw;

    @media screen and (min-width: $breakpoint-small) {
      aspect-ratio: 16/9;
      background-image: url("#{$images-path}/layout/hero-blue.svg"), url("#{$images-path}/layout/hero-red.svg");
      background-position: left top, right center;
      background-repeat: no-repeat;
      background-size: 36vw, 10vw;
    }


    video {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: -1;
      height: 100%;
      object-fit: cover;
    }

    & > div {
      margin: 0 30px;
      max-width: 580px;
    }

    * {
      color: white;
      font-weight: bold;
    }

    h1 {
      font-style: italic;
    }

    p {
    }
  }

  > .icons {

    margin: auto;
    max-width: 960px;
    background-repeat: no-repeat;

    @media screen and (max-width: $breakpoint-medium) {
      margin: 0 24px;
      padding: 24px 0 66px;
      transform: translate(0, -20%);
      background-image: url(#{$images-path}/layout/background-icons-mobile.svg);
      background-size: 100%;
    }
    @media screen and (min-width: $breakpoint-medium) {
      transform: translate(0, -50%);
      background-image: url(#{$images-path}/layout/background-icons-desktop.svg);
      padding: 36px 24px 56px 64px;
    }

    & > div.uk-grid {
      margin-left: 0;
      align-items: center;
    }

    .icon {
      padding: 20px;
      color: $domestiko-blue;
      background-repeat: no-repeat;

      &.-free {
        background-image: url("#{$icons-path}/landing/free.svg");
      }

      &.-easy {
        background-image: url("#{$icons-path}/landing/easy.svg");
      }

      &.-quick {
        background-image: url("#{$icons-path}/landing/quick.svg");
      }

      &.-secure {
        background-image: url("#{$icons-path}/landing/secure.svg");
      }

      @media screen and (max-width: $breakpoint-medium) {
        margin: 12px 0;
        padding-top: 64px;
        background-position: center top;
        text-align: center;
      }
      @media screen and (min-width: $breakpoint-medium) {
        padding-left: 84px;
        background-position: left 16px center;
      }

      strong {
        display: block;
      }
    }
  }

  > .content {
    padding: 30px 0;
    margin: 90px 0;
    position: relative;
    background: $domestiko-light;

    &:after,
    &:before {
      content: '';
      width: 100%;
      height: 72px;
      display: block;
      position: absolute;
      z-index: -1;
    }
    &:before {
      top: -70px;
      background: url("#{$images-path}/layout/angle-up.svg") no-repeat center top / cover;
    }
    &:after {
      bottom: -70px;
      background: url("#{$images-path}/layout/angle-down.svg") no-repeat center bottom / cover;
    }

    .step {
      margin-top: 30px;

      h3, h4 {
        margin: 0;
        font-weight: bold;
      }

      h3 {
        color: $domestiko-dark;
      }

      h4, p {
        color: $domestiko-blue;
      }

      .content {

        position: relative;
        padding-top: 156px;

        &:before {
          content: '01';
          position: absolute;
          top: 0;
          left: 30px;
          font-weight: bold;
          color: $domestiko-gray;
          font-size: 10rem;
          line-height: 1em;
        }
      }

      .animation > * {
        margin: auto;
      }

    }

    .step-01 {
      .animation {
        @media screen and (min-width: $breakpoint-small) {
          order: -1;
        }
      }
    }

    .step-02 {
      .content:before {
        content: '02'
      }
    }

  }

}