
body .term-search {

  position: relative;

  .results {

    left: 1px;
    right: 1px;
    display: none;
    position: absolute;
    z-index: 999;

    max-height: 66vh;
    overflow: auto;

    &.-open {
      display: block;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      background: $domestiko-light;

      li {
        margin: 0;
        padding: 0;

        &.selected {
          background: $domestiko-gray;
        }

        & > * {
          display: block;
          padding: 10px 10px;
          color: $domestiko-dark;
          border-top: solid 1px fade-out($domestiko-dark, 0.90);
          a{
            color: $domestiko-red;
          }
        }
      }
    }

  }

  span.clear {
    top: 9px;
    right: 1px;
    padding: 3px;
    position: absolute;
    color: fade-out($domestiko-dark, 0.5);
    background: white;
    border-radius: 50%;
    cursor: pointer;
  }

}

.term-browse{
  li{
    a{
      display: block;
      position: relative;
      padding-right: 1.5em !important;
      &:after{
        top: .66em;
        right: .5em;
        position: absolute;
        content: "\e90f";
        color: $domestiko-dark;
        font-family: $icomoon-font-family;
      }
      &.back{
        padding-left: 1.5em !important;
        &:after{
          top: .66em;
          left: .5em;
          position: absolute;
          content: "\e90e";
          color: $domestiko-dark;
          font-family: $icomoon-font-family;
        }
      }
    }
  }
  .term-search.activities &{
    &.level1{
      li a:not(.back){
        &:after{
          content: '';
        }
      }
  }
  }
}