main.blog,
main.archive,
section.bloque-blog{

  .post{
    &.-fixed{
      margin-bottom: 30px;
    }
  }

  article{

    padding: 20px;
    background: white;
    border: solid 1px $domestiko-sand;

    .entry-header{
      .thumbnail{
        img{
          width: 100%;
          object-fit: cover;
          aspect-ratio: 4/3;
        }
      }
    }

    .entry-title{
      margin: 20px 0;
      font-weight: bold;
      font-size: $base-h2-font-size;
      a{ color: $domestiko-red; }
    }

    .entry-meta{
      margin: 20px 0;
      font-size: 0.9rem;
    }

    .entry-footer{
      a{
        color: $domestiko-red;
      }
    }

  }

  ul.simpletoc-list{
    margin-bottom: 2em;
    li{
      margin: 5px 0;
      a{
        color: $domestiko-dark;
      }
    }
  }

  body.home & + div.footer {
    background-image: url("/assets/img/waves/platinum-top.svg");
  }

  .author-avatar{
    max-width: 24px;
  }

  .socials{
    a{
      color: #999;
      text-decoration: none;
      margin: 0 3px;
    }
  }

}

main.single-post{

  .entry-content{
    img{
      //padding: 15px;
      margin-bottom: 30px;
      //border: solid 1px $domestiko-sand;
    }
  }

}

main.single-help{
  #sidebar{
    padding-bottom: 60px;
    border-right: solid 1px $domestiko-sand;
    @media all and (max-width: $breakpoint-medium) {
      margin-top: 60px;
      border-right: none;
    }


    .uk-accordion{
      > li{
        margin-top: 0;
        border-top: solid 1px $domestiko-sand;
        > a{
          position: relative;
          padding: 15px;
          font-size: 1rem;
          &:after{
            position: absolute;
            right: 10px; top: 0; bottom: 0;
            content: $icon-chevron-down;
            font-family: domestikons;
            font-size: 1.25rem;
            line-height: 50px;
          }
        }
        &.uk-open{
          > a{
            background: $domestiko-sand;
            &:after{
              content: $icon-chevron-up;
            }
          }
        }
        div.uk-accordion-content{
          margin-top: 0;
          padding: 15px;
          a{
            color: $domestiko-red;
            text-decoration: underline;
          }
          li.uk-current{
            a{
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

.taxonomy-cards{
  .section{
    padding: 30px;
    border: solid 3px $domestiko-sand;
    text-align: center;
    h2{
      color: $domestiko-blue;
      font-size: 1.5rem;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

.terms-cards{
  h2{
    font-size: 1.5rem;
    color: $domestiko-blue;
    font-weight: bold;
    text-transform: uppercase;
  }
  ul.uk-list{
    li{
      a{
        color: $domestiko-red;
        text-decoration: underline;
      }
    }
  }
}

.widget-area{
  .widget{
    padding: 30px 0;
    border-bottom: solid 1px $domestiko-sand;
    &:last-child{
      border: none;
    }
    h2.widget-title{
      font-size: 1.5rem;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

.post-navigation,
.posts-navigation{

  .nav-links{
    @extend .uk-flex;
    @extend .uk-flex-between;
  }

}