
.dmtk-results{

  display: grid;
  grid-row-gap: 45px;
  grid-column-gap: 60px;

  @media screen and (min-width: $breakpoint-medium){

    grid-template-columns: 1fr 2fr;
    //grid-template-rows: repeat(5, auto);

    > .search{
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }
    > .items{
      grid-column-start: 2;
      grid-row: 1 / span 3;
    }
    > .extra-banners{
      grid-column-start: 2;
    }
    > .extra-links{
      grid-row: 2 / span 4;
    }

  }

  .uk-button .icon-equalizer{
    font-size: .75em;
    margin-right: 5px;
  }

}

.dmtk-profile{

  @media screen and (min-width: $breakpoint-medium) {

    display: grid;
    grid-row-gap: 0;
    grid-column-gap: 60px;

    grid-auto-flow: dense;
    grid-template-columns: 240px 2fr 1fr;
    grid-template-rows: repeat(8, auto);

    > .photo{
      grid-column: 1;
      grid-row: 1;
    }
    > .content{
      grid-row: 1 / span 6;
      grid-column: 2;
    }
    > .meta{
      grid-row: 1 / span 2;
      grid-column: 3;
    }
    > .services{
      grid-column: 2;
    }
    > .jobs{
      grid-column: 2;
    }
    > .share{
      grid-column: 3;
    }
    > .extra-links{
      grid-column: 1;
      grid-row: 2 / span 3;
    }
    > .extra-banners{
      grid-column: 3;
    }

  }

}

.dmtk-job{

  display: grid;
  grid-row-gap: 30px;
  grid-column-gap: 60px;

  @media screen and (min-width: $breakpoint-medium) {

    grid-template-columns: 240px 2fr 1fr;
    grid-template-rows: repeat(3, auto);

    > .extra-links{
      grid-column: 1;
      grid-row: 1 / span 3;
    }

    > .content{
      grid-column: 2;
      grid-row: 1;
    }

    > .related{
      grid-column: 2;
      grid-row: 2;
    }

    > .meta{
      grid-column: 3;
      grid-row: 1 / span 3;
    }




  }

}