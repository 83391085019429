
.avatar{

  --size: 80px;

  width: var(--size);
  height: var(--size);

  text-transform: uppercase;

  &.-medium{
    --size: 150px;
  }
  &.-large,
  .signup .uk-uploader &,
  .user-content .uk-uploader &{
    --size: 240px;
  }
  &.-xlarge{
    --size: 300px;
  }

  img{
    border-radius: 50%;
    width: var(--size);
    height: var(--size);
    object-fit: cover;
  }

  a{
    text-decoration: none;
  }

  .uk-uploader &{
    cursor: pointer;
  }

  .-default{
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--size);
    height: var(--size);
    color: currentColor;
    font-family: Arial, sans-serif;
    background: $domestiko-gray;
    border: solid 2px currentColor;
    border-radius: 50%;
    span{
      font-weight: 200;
      font-size: calc( var(--size) * 0.75 );
    }
    .-employer &{
      min-width: 50px;
      span{ font-size: 3em; }
    }

    body.index #main-menu &{
      color: white;
      background: transparent;
    }
    body.index #main-menu.uk-active &,
    body.index.-search-bar #main-menu &{
      color: initial;
      background: transparent;
    }

  }

  .-type-empresa &{
    color: $domestiko-blue;
  }
  .-type-particular &{
    color: $domestiko-red;
  }

  #main-menu &{
    --size: 42px;

    + .uk-badge{
      top: -4px; right: -4px;
      position: absolute;
    }
  }


}