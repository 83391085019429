
//importamos lo que haga falta

@import "partials/base/variables";

@import "../../../node_modules/uikit/src/scss/variables";

@import "../../../node_modules/uikit/src/scss/mixins";
@import "partials/base/mixins";

@import "../../../node_modules/uikit/src/scss/uikit";

@import "../fonts/domestikons/variables";
@import "../fonts/domestikons/style.scss";
//@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.bunny.net/css?family=open-sans:300,400,600,700,800');


/**
  Elementos básicos
 */
@import "partials/base/util";
@import "partials/base/paddings";

@import "partials/elements/text";
@import "partials/elements/fonts";
@import "partials/elements/forms";
@import "partials/elements/buttons";
@import "partials/elements/headings";
@import "partials/elements/sections";
@import "partials/elements/offcanvas";
@import "partials/elements/grid";
@import "partials/elements/lists";
@import "partials/elements/pagination";
@import "partials/elements/progress";
@import "partials/elements/birthdate-picker";
@import "partials/elements/multi-check";
@import "partials/elements/term-search";
@import "partials/elements/avatars";
@import "partials/elements/map";
@import "partials/elements/ads";

/**
  Bloques de elementos
 */


@import "partials/blocks/navigation";
@import "partials/blocks/breadcrumbs";
@import "partials/blocks/content";
@import "partials/blocks/hero";
@import "partials/blocks/share";
@import "partials/blocks/info-box";
@import "partials/blocks/search-form";
@import "partials/blocks/uploader";
@import "partials/blocks/footer";
@import "partials/blocks/cookies";

@import "partials/blocks/panel_notifications";
@import "partials/blocks/panel_messages";
@import "partials/blocks/panel_jobs";

@import "partials/blocks/services";
@import "partials/blocks/banners";


/**
  Estilos de páginas en concreto
 */

@import "partials/pages/blog";
@import "partials/pages/home";
@import "partials/pages/single";
@import "partials/pages/landing-jobs";
@import "partials/pages/profiles-sector";
@import "partials/pages/signup";
@import "partials/pages/register";
@import "partials/pages/sitemaps";
@import "partials/pages/panel";