

.single{

  &.-profile{
    .photo{
      img{
        border-radius: 50%;
        border: solid 3px $domestiko-red;
      }
      .avatar{
        border-width: 5px;
        span{
          font-size: 5em;
          font-weight: bold;
        }
      }
    }
  }

  &.-job{
    .apply-button + .uk-button-bookmark{
      @extend .uk-margin-top;
    }
    .apply-button.applied + .uk-button-bookmark{
      display: none;
    }
  }

  .content{
    h3{
      @extend .dmtk-blue;
      @extend .uk-text-bold;
      @extend .uk-text-uppercase;
      margin: 30px 0 5px;
      & + h4{
        margin-top: 10px;
      }
    }
    h4{
      margin: 20px 0 5px;
      @extend .uk-text-bold;
      & + p{
        margin: 5px 0;
      }
    }
  }

  .meta{
    .info{
      padding: 20px;
      //max-width: 240px;
      border: solid 2px $domestiko-sand;
      .field{
        margin-bottom: 20px;
        padding-left: 36px;
        background: no-repeat top -3px left;
        a{
          @extend .underlined;
        }
        &.-employer {
          padding-left: 0;
        }
        &.-location{
          background-image: url("#{$icons-path}/basic/place.svg");
        }
        &.-birthday{
          background-image: url("#{$icons-path}/basic/birthday.svg");
        }
        &.-country{
          background-image: url("#{$icons-path}/basic/world.svg");
        }
        &.-calendar{
          background-image: url("#{$icons-path}/basic/calendar.svg");
        }
      }
    }
  }

}