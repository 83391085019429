$icomoon-font-family: "domestikons" !default;
$icomoon-font-path: "../fonts/domestikons/fonts" !default;

$icon-warning: unquote('"\\e93b"');
$icon-equalizer: unquote('"\\e93c"');
$icon-x: unquote('"\\e93a"');
$icon-twitter: unquote('"\\e93a"');
$icon-archivo: unquote('"\\e900"');
$icon-attach: unquote('"\\e901"');
$icon-birthday: unquote('"\\e902"');
$icon-calendar: unquote('"\\e903"');
$icon-caret-down: unquote('"\\e904"');
$icon-caret-left: unquote('"\\e905"');
$icon-caret-right: unquote('"\\e906"');
$icon-caret-up: unquote('"\\e907"');
$icon-chat-archivo-path1: unquote('"\\e908"');
$icon-chat-archivo-path2: unquote('"\\e909"');
$icon-check: unquote('"\\e90a"');
$icon-checked-empty: unquote('"\\e90b"');
$icon-checked-full: unquote('"\\e90c"');
$icon-chevron-down: unquote('"\\e90d"');
$icon-chevron-left: unquote('"\\e90e"');
$icon-chevron-right: unquote('"\\e90f"');
$icon-chevron-up: unquote('"\\e910"');
$icon-close: unquote('"\\e911"');
$icon-delete-path1: unquote('"\\e912"');
$icon-delete-path2: unquote('"\\e913"');
$icon-denuncia: unquote('"\\e914"');
$icon-ellipsis: unquote('"\\e915"');
$icon-empleo: unquote('"\\e916"');
$icon-facebook: unquote('"\\e917"');
$icon-filter: unquote('"\\e918"');
$icon-info: unquote('"\\e919"');
$icon-linkedin: unquote('"\\e91a"');
$icon-locked: unquote('"\\e91b"');
$icon-mail: unquote('"\\e91c"');
$icon-main-menu: unquote('"\\e91d"');
$icon-minus: unquote('"\\e91e"');
$icon-order: unquote('"\\e91f"');
$icon-pinterest: unquote('"\\e920"');
$icon-place: unquote('"\\e921"');
$icon-plus: unquote('"\\e922"');
$icon-presupuesto: unquote('"\\e923"');
$icon-question: unquote('"\\e924"');
$icon-radio-selected: unquote('"\\e925"');
$icon-radio-unselected: unquote('"\\e926"');
$icon-search: unquote('"\\e927"');
$icon-send: unquote('"\\e928"');
$icon-twitter1: unquote('"\\e929"');
$icon-unchecked: unquote('"\\e92a"');
$icon-user: unquote('"\\e92b"');
$icon-whatsapp: unquote('"\\e92c"');
$icon-world: unquote('"\\e92d"');
$icon-crear-oferta: unquote('"\\e92e"');
$icon-crear-perfil: unquote('"\\e92f"');
$icon-disponibilidad: unquote('"\\e930"');
$icon-experiencia: unquote('"\\e931"');
$icon-gratis: unquote('"\\e932"');
$icon-informacion: unquote('"\\e933"');
$icon-intermediarios: unquote('"\\e934"');
$icon-notificaciones: unquote('"\\e935"');
$icon-octopus: unquote('"\\e936"');
$icon-rapido: unquote('"\\e937"');
$icon-seguro: unquote('"\\e938"');
$icon-phone: unquote('"\\e939"');
$icon-bookmark: unquote('"\\f02e"');
$icon-bookmark-o: unquote('"\\f097"');
$icon-facebook1: unquote('"\\f09a"');
$icon-facebook-f: unquote('"\\f09a"');
$icon-linkedin1: unquote('"\\f0e1"');

