

.uk-padding-right{ padding-right: $margin-margin; }
.uk-padding-small-right{ padding-right: $margin-small-margin; }
.uk-padding-medium-right{ padding-right: $margin-medium-margin; }
.uk-padding-large-right{ padding-right: $margin-large-margin; }
.uk-padding-xlarge-right{ padding-right: $margin-xlarge-margin; }

@media (min-width: $breakpoint-small) {

  .uk-margin-right\@s {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .uk-margin-right-left\@s { margin-left: auto !important; }
  .uk-margin-right-right\@s { margin-right: auto !important; }

}
@media (min-width: $breakpoint-medium) {

  .uk-margin-right\@m {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .uk-margin-right-left\@m { margin-left: auto !important; }
  .uk-margin-right-right\@m { margin-right: auto !important; }

}
@media (min-width: $breakpoint-large) {

  .uk-margin-right\@l {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .uk-margin-right-left\@l { margin-left: auto !important; }
  .uk-margin-right-right\@l { margin-right: auto !important; }

}