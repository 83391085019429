.multi-check{
  position: relative;
  .uk-textarea {
    .uk-badge {
      margin-right: 3px;
      color: $domestiko-dark !important;
      background: $domestiko-gray;
    }
  }
  .options {
    position: absolute;
    z-index: 9;
    left: 0; right: 0;
    padding: 5px 10px;
    background: $domestiko-light;

    & > div {
      margin: 8px 0;
    }

  }
}