@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?m39znq');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?m39znq#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?m39znq') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?m39znq') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?m39znq##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-warning {
  &:before {
    content: $icon-warning; 
  }
}
.icon-equalizer {
  &:before {
    content: $icon-equalizer; 
  }
}
.icon-x {
  &:before {
    content: $icon-x; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-archivo {
  &:before {
    content: $icon-archivo;
  }
}
.icon-attach {
  &:before {
    content: $icon-attach;
  }
}
.icon-birthday {
  &:before {
    content: $icon-birthday;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-caret-down {
  &:before {
    content: $icon-caret-down;
  }
}
.icon-caret-left {
  &:before {
    content: $icon-caret-left;
  }
}
.icon-caret-right {
  &:before {
    content: $icon-caret-right;
  }
}
.icon-caret-up {
  &:before {
    content: $icon-caret-up;
  }
}
.icon-chat-archivo .path1 {
  &:before {
    content: $icon-chat-archivo-path1;  
    color: rgb(191, 182, 164);
  }
}
.icon-chat-archivo .path2 {
  &:before {
    content: $icon-chat-archivo-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-checked-empty {
  &:before {
    content: $icon-checked-empty;
  }
}
.icon-checked-full {
  &:before {
    content: $icon-checked-full;
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down;
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left;
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right;
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-delete .path1 {
  &:before {
    content: $icon-delete-path1;  
    color: rgb(255, 255, 255);
  }
}
.icon-delete .path2 {
  &:before {
    content: $icon-delete-path2;  
    margin-left: -1em;  
    color: rgb(13, 52, 121);
  }
}
.icon-denuncia {
  &:before {
    content: $icon-denuncia;
  }
}
.icon-ellipsis {
  &:before {
    content: $icon-ellipsis;
  }
}
.icon-empleo {
  &:before {
    content: $icon-empleo;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-filter {
  &:before {
    content: $icon-filter;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;
  }
}
.icon-locked {
  &:before {
    content: $icon-locked;
  }
}
.icon-mail {
  &:before {
    content: $icon-mail;
  }
}
.icon-main-menu {
  &:before {
    content: $icon-main-menu;
  }
}
.icon-minus {
  &:before {
    content: $icon-minus;
  }
}
.icon-order {
  &:before {
    content: $icon-order;
  }
}
.icon-pinterest {
  &:before {
    content: $icon-pinterest;
  }
}
.icon-place {
  &:before {
    content: $icon-place;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-presupuesto {
  &:before {
    content: $icon-presupuesto;
  }
}
.icon-question {
  &:before {
    content: $icon-question;
  }
}
.icon-radio-selected {
  &:before {
    content: $icon-radio-selected;
  }
}
.icon-radio-unselected {
  &:before {
    content: $icon-radio-unselected;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-send {
  &:before {
    content: $icon-send;
  }
}
.icon-twitter1 {
  &:before {
    content: $icon-twitter1;
  }
}
.icon-unchecked {
  &:before {
    content: $icon-unchecked;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp;
  }
}
.icon-world {
  &:before {
    content: $icon-world;
  }
}
.icon-crear-oferta {
  &:before {
    content: $icon-crear-oferta;     
    color: #db668b;
  }
}
.icon-crear-perfil {
  &:before {
    content: $icon-crear-perfil;     
    color: #db668b;
  }
}
.icon-disponibilidad {
  &:before {
    content: $icon-disponibilidad;     
    color: #db668b;
  }
}
.icon-experiencia {
  &:before {
    content: $icon-experiencia;     
    color: #db668b;
  }
}
.icon-gratis {
  &:before {
    content: $icon-gratis;     
    color: #db668b;
  }
}
.icon-informacion {
  &:before {
    content: $icon-informacion;     
    color: #db668b;
  }
}
.icon-intermediarios {
  &:before {
    content: $icon-intermediarios;     
    color: #db668b;
  }
}
.icon-notificaciones {
  &:before {
    content: $icon-notificaciones;     
    color: #db668b;
  }
}
.icon-octopus {
  &:before {
    content: $icon-octopus;     
    color: #c3063e;
  }
}
.icon-rapido {
  &:before {
    content: $icon-rapido;     
    color: #db668b;
  }
}
.icon-seguro {
  &:before {
    content: $icon-seguro;     
    color: #db668b;
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-bookmark {
  &:before {
    content: $icon-bookmark; 
  }
}
.icon-bookmark-o {
  &:before {
    content: $icon-bookmark-o; 
  }
}
.icon-facebook1 {
  &:before {
    content: $icon-facebook1; 
  }
}
.icon-facebook-f {
  &:before {
    content: $icon-facebook-f; 
  }
}
.icon-linkedin1 {
  &:before {
    content: $icon-linkedin1; 
  }
}

