

.readmore{

  position: relative;
  max-height: 150px;
  overflow-y:hidden;
  @extend .uk-margin-large-bottom;

  &:after{
    content: '';
    height: 100px;
    background: linear-gradient(0deg, white 50%, #ffffff00 100%);
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .uk-button-readmore{
    @extend .uk-button;
    position: absolute;
    z-index: 9;
    bottom: 0;
    left: 0;
    right: 0;
  }

}