
.dmtk-white{
  color: white;
}
.dmtk-dark{
  color: $domestiko-dark;
}
.dmtk-blue{
  color: $domestiko-blue;
}
.dmtk-red{
  color: $domestiko-red;
}