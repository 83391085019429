
.bloque-hero {
  display: flex;
  min-height: 40vh;
  padding: 120px 0;
  align-items: center;
  color: white;
  background: $domestiko-blue;

  @media screen and (min-width: 768px) {
    padding: 80px 0 10vw 0;
  }

  h1, h2, a, p {
    color: white;
  }

  &.-home {
    background-image: url('/assets/img/waves/white-bottom.svg'),
                      url('/assets/img/sea/blue-waves.jpg');
    background-position: center bottom, center center;
    background-repeat: no-repeat;
    background-size: auto, cover;
    .-column{
      &.-second{
        text-align: right;
      }
      @media screen and (max-width: $breakpoint-medium) {
        margin-bottom: 30px;
        &.-first, &.-second{
          text-align: center;
        }
      }
    }
  }

  .pulpo-divider {
    position: relative;

    &:before {
      $size: 460px;
      content: '';
      position: absolute;
      bottom: calc($size/-1.5);
      left: calc(50% - #{$size} / 2 + 15px);
      width: $size;
      height: $size;
      background: url('/assets/img/logo/logo-reduccion-color.png') no-repeat center center / contain;
      pointer-events: none;
    }

    @media screen and (max-width: $breakpoint-medium) {
      order: 1;
      &:before{
        $size: 160px;
        width: $size;
        height: $size;
        left: calc(50% - #{$size} / 2 + 15px);
        bottom: auto;
      }

    }

  }

  &.-directorio{
    background: $domestiko-blue url('/assets/img/sea/yellow-fishes.jpg') no-repeat center center / cover;
  }
  &.-empleo{
    background: $domestiko-blue url('/assets/img/sea/sea-coral.jpg') no-repeat center center / cover;
  }

}