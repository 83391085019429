.services-list{

  .service{
    @extend .uk-margin;
    @extend .uk-padding-small;
    border-radius: $dmtk-border-radius;
    border: solid 1px $domestiko-sand;

    h4{
      a{
        color: $domestiko-red;
      }
      .single &{
        margin-top: 0;
      }
    }

    > .photo{
      img{
        width: 100%;
        max-height: 100%;
        margin-bottom: 15px;
        object-fit: cover;
      }
      @media screen and (min-width: $breakpoint-small) {
        width: 120px;
        height: 120px;
        margin-right: 15px;
      }
    }
    > .info{
      @extend .uk-width-expand;
    }

  }

  &.-collapsed{
    .service{
      cursor: pointer;
      @extend .uk-margin-remove;
      border: none;
      border-radius: 0;
      border-top: solid 1px $domestiko-sand;
      &:last-child{
        border-bottom: solid 1px $domestiko-sand;
      }
      &.-active{
        .info{
          strong{
            @extend .dmtk-blue;
          }
        }
      }
      &.-inactive{
        .info{
          opacity: 0.5;
        }
      }
    }
  }

}

.single.service{


  .wrapper{

    @media screen and (min-width: $breakpoint-medium){
      display: grid;
      grid-template-columns: 240px 2fr 1fr;
      grid-template-rows: 360px 1fr;
      grid-column-gap: 60px;

      .photo{
        grid-row: 1 / 2;
      }
      .content{
        grid-row: 1 / span 2;
      }
      .meta{}
      .extra{}

    }

  }

  .gallery{
    text-align: center;
    .image{
      display: block;
      margin: 15px auto;
    }
  }
}

#app .user-content .gallery{

  @extend .uk-grid;

  .image{
    position: relative;
    img{
      width: 120px;
      height: 120px;
      object-fit: cover;
    }
    button.-edit{
      width: 32px;
      height: 32px;
      z-index: 1;
      position: absolute;
      top: -16px; right: -16px;
      padding: 10px;
      color: white;
      background: $domestiko-red url('#{$icons-path}/basic/edit-white.svg') no-repeat center center / 16px;
      border: none;
      border-radius: 50%;
      text-indent: -9999px;
      cursor: pointer;
    }
  }

}