
.banner-panel{
  @extend .uk-flex;
  @extend .uk-margin-top;
  @extend .clickable;
  display: flex;
  align-items: center;
  background: $alert-warning-background;
  padding: 15px;
  border-radius: 5px;
  & > .icon{
    width: 48px;
    margin-right: 20px;
  }
  & > .text{
    @extend .uk-width-expand;
  }
}


.banner-middle{
  margin-top: $margin-xlarge-margin * 2;
  padding: 120px 0 240px;
  color: white;
  background-color: $domestiko-blue;
  background-image: url('/assets/img/waves/white-bottom.svg'),
  url('/assets/img/sea/yellow-fishes.jpg');
  background-position: center bottom, center center;
  background-repeat: no-repeat;
  background-size: auto, cover;
  h2, .uk-h2, h3, .uk-h3{
    color: white;
  }

}

.banner-footer{

  padding: 120px 0 360px;

  color: white;
  background: $domestiko-blue url('/assets/img/sea/yellow-fishes.jpg') no-repeat center center / cover;

  &.-directorio{
    background: $domestiko-dark;
  }

  margin-top: -260px;
  transform: translateY(260px);

  h1, h2, .uk-h1, .uk-h2{
    color: white;
  }

  & + .footer{
    background-image: none;
    &:before{
      content: '';
      transform: translateY(60px);
      position: absolute; display: block;
      height: 280px; width: 100%;
      background: url('/assets/img/waves/blue-bottom.svg') no-repeat center center / cover;
    }
    & > div{
      position: relative;
    }
  }

}
