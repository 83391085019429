
body.signup.profiles{

  .signup-menu{
    margin: 60px 0;
    a{
      opacity: 0.4;
      img{
        margin: auto;
        display: block;
      }
      span{
        color: black;
        text-transform: uppercase;
        @extend .uk-visible\@m;
      }
      &.router-link-active{
        opacity: 1;
      }
    }

  }


}