
.uk-pagination{

  text-transform: uppercase;

  .uk-active{
    > a{
      color: $domestiko-pink;
      border: solid 1px currentColor;
    }
  }



}