
#app{

  position: relative;

  margin-top: 36px;
  @media screen and (max-width: $breakpoint-small){
    margin-top: 42px;
  }

  .user-menu,
  .user-dropdown-menu{
    li{
      a{
        padding: 10px;
        color: $domestiko-dark;
        @extend .uk-text-uppercase;
        justify-content: space-between;
        &.router-link-active{
        }
        > .uk-badge{
          background-color: $domestiko-dark;
          &.uk-badge-unread{
            background-color: $domestiko-red;
          }
        }
      }
    }
  }

  .user-menu{
    ul{
      margin-bottom: 30px;
      li{
        a{
          border-bottom: solid 1px $domestiko-sand;
          &.router-link-active{
            background: $domestiko-sand;
          }
        }
      }
    }
  }

  .user-content{
    border-left: solid 1px $domestiko-sand;

    h3{
      color: $domestiko-blue;
      font-weight: bold;
    }

  }

  .uk-subnav{
    a{
      color: $domestiko-dark;
      @extend .uk-text-uppercase;
      text-decoration: none;
      &.router-link-active{
        color: $domestiko-red;
      }
    }
    .uk-pin{
      @extend .uk-badge;
      top: 0;
      right: -12px;
      position: absolute;
      height: 8px;
      min-width: 8px;
      padding: 0;
      &.-warning{
        background: $alert-warning-color;
      }
    }
  }

  .user-password-form{
    &>div.uk-grid{
      @extend .uk-child-width-1-2\@m
    }
  }

  .user-dropdown-menu{
    z-index: 99;
    position: fixed;
    top: 80px;
    left: -15px; right: -15px;
    > button{
      @extend .uk-text-left;
      @extend .uk-text-bold;
      @extend .uk-text-uppercase;
      background: $domestiko-sand;
      > i{
        top: 4px;
        position: relative;
        font-size: 1.5em;
      }
      & + div{
        @extend .uk-text-uppercase;
        background: $domestiko-gray;
        a{
          color: $domestiko-dark;
          padding-left: 20px;
        }
      }
    }
  }

  .uk-alert{
    padding: 30px;
    border-radius: 5px;
    &.uk-flex{
      > .icon{
        font-size: 2rem;
        margin-right: 1rem;
      }
      > p{
        @extend .uk-margin-remove;
      }
      display: flex;
      align-items: center;
    }
  }

  @media screen and (min-width: $breakpoint-medium) {
  }
  @media screen and (max-width: $breakpoint-medium) {
    .user-content{
      .uk-subnav{
        padding: 12px 0;
        flex-wrap: nowrap;
        overflow-x: auto;
      }
    }
  }

  .component-fade-enter-active, .component-fade-leave-active {
    transition: opacity .33s ease;
  }
  .component-fade-enter, .component-fade-leave-to{
    opacity: 0;
  }

}