
body.panel .jobs,
body.panel .applications
{

  .job-list,
  .application-list
  {

    .job,
    .application
    {

      @extend .uk-padding-small;
      border-top: solid 1px $domestiko-sand;

      .info {
        opacity: 0.8;
      }

      &.-active .info {
        opacity: 1;

        strong {
          @extend .dmtk-blue;
        }
      }

      &.-active,
      &.-pending{
        cursor: pointer;
      }
      &.-closed,
      &.-inactive {
        .info {
          opacity: 0.5;
        }
      }
      &.-closed{
        background: #fafafa;
      }

      &:last-child {
        border-bottom: solid 1px $domestiko-sand;
      }

      .uk-dropdown-nav {
        a {
          color: $domestiko-dark;
        }
      }
    }
  }

}