
.uk-section{

  &.uk-section-platinum{
    background: #EDEBEB;
  }

  &.-wave-bottom{
    padding-bottom: 240px;
    background-image: url('/assets/img/waves/white-bottom.svg');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  &.-banner{
    margin: 30px 0;
    padding: 30px;
    border: solid 4px $domestiko-sand;
    text-align: center;
    @media screen and (min-width: $breakpoint-medium) {
      padding: 60px;
    }
    p{
      margin: 15px;
    }
    a{
      margin: 30px 0;
    }
  }

}