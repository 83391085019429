
.icon-phone{
  &:before{
    font-size: 24px;
  }
}
.icon-info{
  &:before{
    top: 5px;
    position: relative;
    font-size: 24px;
  }
}