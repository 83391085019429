
.breadcrumbs-wrapper{
  background: $domestiko-sand;

  .uk-breadcrumb {
    display: flex;
    padding: 10px 0;
    @media screen and (max-width: $breakpoint-medium){
      max-width: 100vw;
      overflow-x: scroll;
    }
    > li{
      > a,
      > span{
        //max-width: 25vw;
        //overflow: hidden;
        white-space: nowrap;
        //text-overflow: ellipsis;
      }
    }
  }

}