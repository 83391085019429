
.footer{
  color: white;
  background: $domestiko-blue url('/assets/img/waves/white-top.svg') no-repeat center top;
  a{
    color: white;
  }

  .socials a{
    font-size: 2rem;
    margin: 0 0.5rem;
    text-decoration: none;
  }

}