.notifications-list{

  .notification{

    cursor: pointer;
    @extend .uk-padding-small;
    border-top: solid 1px $domestiko-sand;

    &:last-child{
      border-bottom: solid 1px $domestiko-sand;
    }

    .indicator{
      width: 8px; height: 8px;
      border-radius: 50%;
      border: solid 2px $domestiko-blue-light;
    }

    &.-unread{
      .indicator{
        background: $domestiko-blue-light;
      }
      .message{
        @extend .uk-text-bold;
      }
    }

  }

}