
#mapa-espana{

  path, polygon, polyline{
    cursor: pointer;
    transition: fill 0.2s ease;
    &:hover{
      fill: $domestiko-red !important;
    }
  }

}