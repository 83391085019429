.bloque-info{

  .uk-button{
    @extend .uk-button-bold;
    &.uk-active{
      color: white;
      background: $domestiko-red;
    }
  }

  .info-menu{
    max-width: 780px;
    .uk-subnav-pill{
      @extend .uk-flex-center;
      @extend .uk-flex-around;
      a{
        @extend .uk-button;
        @extend .uk-button-primary;
        @extend .uk-button-ghost;
        @extend .uk-button-bold;
      }
    }
  }

  .info-wrapper{
    @extend .uk-grid;
    @extend .uk-grid-small;
    & > div{
      @extend .uk-width-1-3\@m;
    }
  }

  .info-box{
    @extend .uk-text-center;
    @extend .uk-padding-small;
    @extend .uk-margin-bottom;
    border: solid 1px $domestiko-gray;
    h3{
      @extend .dmtk-blue;
      @extend .uk-text-bold;
      @extend .uk-text-uppercase;
    }
    @media screen and (max-width: $breakpoint-medium){
      margin: 0 15px;
    }
  }

  .uk-accordion{

    .uk-accordion-title{
      @extend .uk-button;
      @extend .uk-display-block;
      @extend .uk-button-primary;
      @extend .uk-button-ghost;
      @extend .uk-button-bold;
      @extend .uk-hidden\@m;
    }

    .uk-accordion-content{
      @media screen and (min-width: $breakpoint-medium) {
        margin: auto;
        max-width: $breakpoint-medium;
      }
    }

  }

}