
.bloque-caracteristicas{
  h2{
    @extend .dmtk-blue;
    @extend .uk-text-bold;
    @extend .uk-text-uppercase;
    @extend .uk-margin-remove;
    @extend .uk-margin-small-top;
  }
  p{
    @extend .uk-margin-small-top;
  }
  a{
    @extend .dmtk-red;
    @extend .uk-text-bold;
    text-decoration: underline;
  }
}