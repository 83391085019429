
.uk-progress{

  $alerts: (
    -danger: $alert-danger-color,
    -warning: $alert-warning-color,
    -success: $alert-success-color,
  );

  @each $alert-key, $alert-background in $alerts {
    &.#{$alert-key}{
      &::-webkit-progress-value{
        background-color: $alert-background;
      }
    }
  }

  .dmtk-textarea & {
    height: 3px;
    position: relative;
    margin: 2px 0 0 0;
    &::-moz-progress-bar{ background: white !important; }
    &::-webkit-progress-bar{ background: white !important; }
  }

}