
.sitemap{

  .sitemap-menu{
    li{
      display: inline-block;
      margin: .25em 1em;
      a{
        font-weight: bold;
        font-size: 1.10em;
        color: $domestiko-red;
      }
    }
  }

  #provincias{
    li{
      a{
        color: $domestiko-blue;
      }
    }
  }

  .sitemap-accordion{
    margin-top: 3em;
    h2{
      @extend h3;
      margin: 2em 0 0.66em;
      color: $domestiko-blue;
      text-transform: uppercase;
      font-weight: bold;
    }
    .uk-accordion{
      & > div{
        margin: 0;
        border-bottom: solid 1px $domestiko-gray;
        h3{
          margin: 0.66em 0;
          padding: 0 0.66em;
          font-size: 1.15em;
          a{
            color: $domestiko-red;
          }
        }
        &:first-child{
          border-top: solid 1px $domestiko-gray;
        }
      }
      .uk-accordion-title{
        margin: 0;
        padding: 0.66em;
        position: relative;
        cursor: pointer;
        &:after{
          right: 0.66em;
          position: absolute;
          color: $domestiko-blue;
          content: $icon-chevron-down;
          font-family: $icomoon-font-family;
          transition: transform 0.25s ease;
        }
      }
      .uk-accordion-content{
        margin: 0;
        padding: 0 0 8px;
        .uk-list{
          margin: 0 0 16px 0.66em;
        }
      }
      .uk-open{
        .uk-accordion-title{
          background: $domestiko-gray;
          &:after{
            transform: rotate(180deg);
          }
        }
      }
    }
  }

}