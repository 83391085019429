
.uk-offcanvas-bar{

  overflow: hidden;

  color: black;
  background: $domestiko-gray url('/assets/img/waves/blue-bottom.svg') no-repeat center bottom;
  &:after{
    z-index: 9;
    content: '';
    height: 140px;
    position: absolute;
    bottom: 20px; left: 0; right: 0;
    background: url('/assets/img/logo/logo-reduccion-color.png') no-repeat center bottom / contain;
  }

  @media (max-width: $breakpoint-small) {
    &:after{
      background-image: none;
    }
  }

  &.-clean{
    background: $domestiko-gray;
    &:after{ display: none; }
  }
  #offcanvas-register &{
    &:after{ height: 80px; }
  }

  a{
    color: $domestiko-red;
  }

  h1, h2, h3, .uk-h2, .uk-h3{
    color: $domestiko-blue;
  }

  .uk-input,
  .uk-select,
  .uk-textarea {
    &, &:focus{
      color: $domestiko-dark;
      background: white;
    }
  }

  .uk-button-primary{
    color: white;
    background: $domestiko-red;
    text-transform: uppercase;
    &.uk-disabled{
      color: $domestiko-gray;
      background: $domestiko-sand;
    }
  }

  .uk-offcanvas-close{
    color: black;
  }

}

#offcanvas{

  .uk-offcanvas-header{
    position: absolute;
    top: 0; left: 0; right: 0;
    height: 32px;
    padding: 16px;
    background: $domestiko-sand;
    img{ height: 36px; }
  }

  .uk-offcanvas-menu{

    width: 100%;
    right: 0;
    top: 64px; bottom: 0;
    position: absolute;
    transition: transform 0.33s ease;

    li{

      a{
        display: block;
        position: relative;
        padding: 20px 10px;
        color: black;
        text-transform: uppercase;
        &:focus{
          color: white;
          background: $domestiko-red;
          -webkit-tap-highlight-color: fade-out(white, 1);
        }
        .next{
          width: 3em;
          z-index: 9;
          position: absolute;
          top: 0; bottom: 0; right: 0;
          display: block;
        }
      }

      &.supermenu a{
        &:after{
          content: '';
          width: 1em; height: 1em;
          position: absolute;
          right: 16px;
          background: url('#{$icons-path}/basic/angle-right.svg') no-repeat center center;
          background-size: 24px;
        }
        &.final:after{
          content: none;
        }
      }

      .submenu{
        z-index: 2;
        position: absolute;
        top: 0;
        width: 100%;
        background: $domestiko-gray;
        left: 100%;
        .back{
          position: absolute;
          width: 42px; height: 64px;
          background: url('#{$icons-path}/basic/angle-right.svg') no-repeat center center;
          background-size: 24px;
          border: none;
          transform: rotate(180deg);
          &:after{
            content: '';
            z-index: 3;
            position: absolute;
            border-right: solid 1px $domestiko-sand;
            left: 0;
            bottom: 0; height: 100vh;
          }
        }
        > ul{
          margin-left: 48px;
          overflow-y: scroll;
          height: calc( 100vh - 80px );
        }
        & + .submenu{
          left: 200%;
          a:after{
            content: none;
          }
        }
      }

      .submenu{ display: none; }
      &.supermenu.active .submenu{ display: block; }

    }

    &.-sectors{
      transform: translateX( -100% );
    }
    &.-activities{
      transform: translateX( -200% );
    }

  }


}