
%unreaded{
  content: '';
  display: block;
  position: absolute;
  top: 0.5em; left: 0;
  width: 8px; height: 8px;
  background: $domestiko-red;
  border-radius: 50%;
}

body.panel .messages{

  .threads{

    height: 66vh;
    overflow: auto;

    border: solid 1px $domestiko-sand;

    ul {
      margin: 0;

      > li {
        margin: 0;
        padding: 15px;
        background: white;
        border-bottom: solid 1px $domestiko-sand;
        cursor: pointer;
        transition: all .25s ease;

        strong{
          position: relative;
          padding-left: 0;
          transition: padding-left ease 0.2s;
        }

        &.-selected {
          background: transparentize($domestiko-sand, 0);
        }

        &.-unread {
          strong{
            padding-left: 16px;
            &:before{ @extend %unreaded; }
          }
        }

      }
    }

  }

  .messages-panel{

    display: flex;
    height: 66vh;
    flex-direction: column;
    border: solid 1px $domestiko-sand;
    border-left: none;

    & > div{
      padding: 10px;
    }

    $trans-sand: transparentize( $domestiko-sand, 0.5);

    .messages-header{
      background: $trans-sand;
    }
    .messages-list{

      flex-grow: 1;
      overflow-y: scroll;

      & > div{
        position: relative;
      }

      .date{
        margin: 15px 0;
        font-size: 0.8em;
        text-align: center;
        span{
          position: relative;
          background: $domestiko-sand;
          padding: 5px 10px;
          border-radius: 5px;
        }
      }
      .message{
        position: relative;
        padding: 10px;
        margin: 0 45px 10px 0;
        border: solid 1px $domestiko-sand;
        border-radius: 15px;

        & > .meta{
          top: 0;
          bottom: 0;
          width: 45px;
          right: -45px;
          position: absolute;
          .actions {
            top: 0;
            left: 16px;
            position: absolute;
            button{
              @extend .uk-button;
              @extend .uk-button-text;
              @extend .icon-chevron-down;
              opacity: .5;
              font-family: '#{$icomoon-font-family}' !important;
            }
          }
          .hour{
            bottom: 5px;
            width: 45px;
            position: absolute;
            text-align: center;
            font-size: .66em;
            opacity: 0.33;
          }
        }

        &.-mine{
          margin: 0 0 10px 45px;
          background: $trans-sand;
          border: none;
          & > .meta{
            right: auto;
            left: -45px;
          }
        }
        &.-pdf, &.-download{
          a.download{
            display: block;
            padding-left: 50px;
            color: $domestiko-soil;
            background: url('#{$icons-path}/basic/file-pdf.svg') no-repeat center left;
          }
        }
        &.-download a{
          background-image: url('#{$icons-path}/basic/file.svg');
        }

        &.-deleted{
          border-color: $alert-danger-background;
          background-color: $alert-danger-background;
        }

      }
      .uk-alert{
        text-align: center;
        border-radius: 15px;
      }

    }
    .messages-input{
      background: $trans-sand;
    }

  }

  .threads,
  .messages-list{
    &::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb{
      background: $domestiko-soil;
      border-radius: 5px;
    }
  }

  .messages-input{
  }

}

@media screen and (max-width: $breakpoint-small-max) {

  #app.-mensajes{

    padding-left: 0;
    padding-right: 0;

    & + .footer{
      display: none;
    }

    .user-content{
      border-left: none;
    }

    .messages{
      > ul{
        margin: 0;
      }

      .threads,
      .messages-panel{
        height: calc( 100vh - 124px );
        .messages-list{
          margin-bottom: 60px;
        }
        .messages-input{
          bottom: 0;
          position: fixed;
          width: calc( 100% - 20px);
          background: $domestiko-sand;
        }
      }

      &.list{
        .messages-panel{
          display: none;
        }
      }
      &.detail{
        .threads{
          display: none;
        }
      }
    }


  }

}