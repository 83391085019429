

.uk-button{
  border: solid 1px transparent;

  &.uk-button-ghost{
    color: $domestiko-red;
    background: transparent;
    border: solid 1px currentColor;
  }
  &.uk-button-bold{
    @extend .uk-text-bold;
    @extend .uk-text-uppercase;
  }

  &.uk-button-dmtk{
    @extend .uk-button;
    @extend .uk-button-primary;
    @extend .uk-button-bold;
    @extend .uk-text-uppercase;
  }

  &.uk-chip{
    color: $domestiko-blue;
    background: white;
    border: solid 1px $domestiko-blue;
    border-radius: 1.5em;
    &.-selected{
      color: white;
      background: $domestiko-blue;
    }
  }

  &.uk-button-ellipsis{
    @extend .uk-icon-button;
    @extend .uk-button-link;
    background: transparent url('#{$icons-path}/basic/ellipsis.svg') no-repeat center center;
  }

  &.uk-button-attach{
    width: 42px;
    text-indent: -9999px;
    @extend .uk-button-link;
    background: url('#{$icons-path}/basic/attach.svg') no-repeat center center;
    background-size: 100%;
    &[disabled], &.uk-disabled{
        opacity: 0.25;
    }

  }
  &.uk-button-send{
    width: 42px;
    text-indent: -9999px;
    @extend .uk-button-link;
    background: url('#{$icons-path}/basic/send.svg') no-repeat center center;
    background-size: 100%;
    &[disabled], &.uk-disabled{
      opacity: 0.25;
    }
  }

  &.-twolines{
    padding: 10px 20px;
    line-height: 1.5em;
  }

  .uk-modal-footer &:disabled{
    background-color: darken($button-disabled-background, 15%);
  }

}