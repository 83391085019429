
@mixin hook-navbar-nav-item{
  text-transform: uppercase;
}

@mixin hook-navbar-nav-item-hover{
  color: white;
  background: $domestiko-red !important;
  border-bottom: lime;
}
@mixin hook-navbar-nav-item-active{
  color: white;
  background: $domestiko-red !important;
  border-bottom: lime;
}

@mixin hook-navbar-dropdown-nav-item{
  text-transform: uppercase;
  padding: 8px 16px;
  margin-left: -16px;
}

@mixin hook-navbar-dropdown-nav-item-active{
  color: white;
  background: $domestiko-red;
}
@mixin hook-navbar-nav-item-hover{
}

@mixin hook-offcanvas-bar{
  max-width: 100vw;
}

@mixin hook-form{
  border-radius: $dmtk-border-radius;
  border: solid 1px $domestiko-blue;
}

@mixin hook-form-radio{
  border-radius: $dmtk-border-radius;
  border: solid 1px $domestiko-blue;
}
@mixin hook-form-radio-checked{
  border: solid 1px $domestiko-red;
}
@mixin hook-form-check-checked{
  border-radius: $dmtk-border-radius;
  border: solid 1px $domestiko-red;
}
@mixin hook-form-danger{
  border-color: $form-danger-color;
}