
.publi{

  max-width: 100%;
  margin: 30px 0 !important;
  text-align: center;
  //border: dashed 2px #888;

  ins{
    background: transparent;
  }

  &.csa{
    width: 100%;
    height: 312px;
    max-width: $container-small-max-width;
    margin: 30px auto !important;
  }

  &.format-auto{
    height: 327px;
  }
  
  @media screen and (max-width: $breakpoint-medium) {
    &.csa{
      height: 306px;
    }
    &.format-auto{
      height: 280px;
    }
  }

  &.clickio{
    //height: 280px;
    @media screen and (min-width: $breakpoint-medium) {
        height: 250px;
    }
  }

  .dmtk-list &{
    margin: 15px 0 !important;
  }
  
}

#lx_715476 .lx_home_button{
  display: none;
}