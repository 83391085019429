
.uk-list {
  &.-checklist {
    li {
      padding-left: 32px;
      background: url('#{$icons-path}/basic/unchecked.svg') no-repeat top -3px left;

      &.uk-active {
        background-image: url('#{$icons-path}/basic/checked-full.svg');
      }
    }
  }

  span.x-remove {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-top: 7px;
    margin-left: 10px;
    background: url(#{$icons-path}/basic/close-black.svg) no-repeat center center;
    cursor: pointer;
  }

}

.activities-list {
  a {
    color: $domestiko-red;
  }
}

.pulpo-list {
  padding: 0;
  list-style: none;

  li {
    display: block;
    break-inside: avoid;
    padding: 10px 0 10px 36px;
    background: url('#{$icons-path}/line/octopus.svg') no-repeat left center;
  }
}

.pulpo-linked-list {
  @extend .uk-column-1-2\@m;
  list-style: none;

  li {
    display: block;
    break-inside: avoid;

    a {
      display: block;
      padding: 10px 0 10px 36px;
      font-weight: bold;
      text-decoration: underline;
      background: url('#{$icons-path}/line/octopus.svg') no-repeat left center;
    }
  }
}

.dmtk-list {

  .dmtk-item {

    padding: 15px;
    margin-bottom: 15px;
    color: $domestiko-dark;
    background: white;
    border: solid 1px $domestiko-dark;
    border-radius: 3px;

    h3 {
      margin-bottom: 5px;
    }

    p {
      margin: 5px 0;
      word-break: break-word;
    }

    a {
      color: $domestiko-dark;
    }

    .uk-h4 {
      color: $domestiko-dark;
      text-decoration: none;
      @extend .uk-text-bold;
    }

    .iconized {
      padding-left: 32px;
      background: url('#{$icons-path}/basic/empleo.svg') no-repeat left center;
    }

    &.-type-empleo,
    &.-type-particular {
      border-color: $domestiko-red;

      h3 a{
        color: $domestiko-red;
      }

      .iconized {
        background-image: url('#{$icons-path}/basic/empleo.svg');
      }
    }

    &.-type-empresa,
    &.-type-presupuesto {
      border-color: $domestiko-blue;

      h3 a {
        color: $domestiko-blue;
      }

      .iconized {
        background-image: url('#{$icons-path}/basic/presupuesto.svg');
      }
    }


  }
}

.search-form {
  .province-list {
    margin: 0;
    padding: 0;
    list-style: none;
    a {
      padding: 10px;
      display: block;
      color: $domestiko-blue;
      border: solid 1px $domestiko-blue;
      text-decoration: none;
    }
    li:not(:last-child) a{
      border-bottom: none;
    }
  }
}

.uk-iconnav.socials {
  margin-top: 1em;
  justify-content: center;
  font-size: 2em;

  .icon-twitter,
  .icon-linkedin1 {
    font-size: 1.5rem;
  }
}