
#cookies-banner{

  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  padding: 10px;

  background: white;
  border-top: solid 3px $domestiko-red ;

  @media screen and (min-width: $breakpoint-medium) {
    padding: 30px;
  }

}