
body .birthdate-picker{

  position: relative;

  .dropdown-picker{
    position: absolute;
    background: $domestiko-light;
    width: 100%;
    &.-closed{
      display: none;
    }
  }

  .picker{
    padding: 1em;
    > ul{
      padding: 0;
      margin: 1em 0 0;
      list-style: none;
      display: grid;
      > li{
        margin: 0;
        padding: 5px;
        border: solid 1px transparent;
        text-align: center;
        cursor: pointer;
        &:hover{
          border-radius: 3px;
          border-color: darken($domestiko-light, 15%);
        }
        &.empty{
          color: darken($domestiko-light, 15%);
          &:hover{
            border-color: transparent;
          }
        }
      }
    }

    &.-day{
        > ul{
          grid-template-columns: repeat(7, auto);
        }
    }
    &.-month{
        > ul{
          grid-template-columns: repeat(3, auto);
        }
    }
    &.-year{
        > ul{
          grid-template-columns: repeat(5, auto);
        }
    }

  }


}